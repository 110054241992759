<template> 
  <div v-if="perms.includes('Facturation Fournisseurs') || perms.includes('Admin')">
     <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else >
      <div class="custom-search">
      <b-row>
        <b-col md="3">
          <b-form-group>
            <label>Société:</label>
            <b-form-input
              placeholder="Chercher"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>ICE:</label>
            <b-form-input
              placeholder="Chercher"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>Adresse:</label>
            <b-form-input
              placeholder="Chercher"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" style="padding-top: 21px;">
          <b-form-group>
           <b-button
                variant="primary"
                class="d-inline-block"
                @click="AddNewFourni()"        
              >
                <span class="text-nowrap">Ajouter Un fournisseur</span>
              </b-button>
          </b-form-group>
        </b-col>
   <!--      <b-col md="4">
          <b-form-group>
             <b-button
                variant="success"
              
              >
                <span class="text-nowrap">Export</span>
              </b-button>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
              <b-button
                variant="warning"
              
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
          </b-form-group>
        </b-col> -->
      </b-row>
    </div>

    <!-- table -->
   <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
     
    > <!--  @on-row-click="onRowClick" -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'NomSociete'"
          class="text-nowrap"
        >
       <!-- http://stock.storefy.ma/back/public -->
          <b-avatar
          :src="`${path}${props.row.logo}`"
            class="mx-1"
          />
          <span>{{ props.row.NomSociete }}</span>
        </span>
         <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span><b-row> <b-button style="margin-right: 4px; margin-left: 5px;"
                        variant="gradient-success"
                        class="btn-icon"
                      v-b-toggle href="#update-fourni-sidebar" @click.prevent @click="showUpdate(props.row)"
                        >
                       <feather-icon icon="Edit2Icon" />
                        
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon"
                        @click="deleteFourni(props.row.id)"
                        >
                        <feather-icon icon="TrashIcon" />
                        
                    </b-button></b-row></span>

        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
     <b-sidebar 
    id="update-fourni-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           Modifier Fournisseur
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >  
        <b-form-group
          label="Nom Société"
          label-for="nom"
        >
          <b-form-input
            id="nom"
            v-model="form.NomSociete"
            trim
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="ICE"
          label-for="Ice"
        >
          <b-form-input
            id="Ice"
            v-model="form.Ice"
            trim
          />
        </b-form-group>
        <b-form-group
              label="Téléphone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                trim
                type="number"
              />
            </b-form-group>
            <b-form-group
              label="Adresse"
              label-for="adresse"
            >
              <b-form-input
                id="adresse"
                v-model="form.adresse"
                trim

              />
            </b-form-group>
            <b-media no-body>
                <input type="file" class="hidden" ref="updateImgInput" @change="imageSelected" accept="image/*">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.updateImgInput.click()"  >
                <!-- $refs.refInputEl.$el.click() -->
                  Importer Logo
                </b-button>
          
            <b-img
        
          v-if="imagepreview" 
          :src="imagepreview" 
          height="80"
        />
            </b-media>
                    <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="UpdateFournisseur()"
          >
            Modifier
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
  </div>
  <div v-else>
    <not-authoriz></not-authoriz>
  </div>
</template>

<script> 
import config from '@/config'
// import BCadCode from '@core/components/b-card-code/BCardCode.vue'
import {
 BCard, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,BDropdown,
  BDropdownItem,BButton,VBToggle,BCollapse,BSidebar,BForm,BImg,BMedia
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import NotAuthoriz from '../NotAuthoriz.vue'




export default {
  components: {
    BButton,BCollapse,BSidebar,BForm,BImg,BMedia,
    BCard,
    VueGoodTable,
    BDropdown,
  BDropdownItem,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,NotAuthoriz
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      path:config.path,
      perms:[],
      loading:false,
      pageLength: 10,
      dir: false,
      UserID:{},
      columns: [
        {
          label: 'Société',
          field: 'NomSociete',
        },
        {
          label: 'ICE',
          field: 'Ice',
        },
        {
          label: 'Téléphone',
          field: 'phone',
        },
        {
          label: 'Adresse',
          field: 'adresse',
        },
        {
          label: 'Date',
          field: 'created_at',
        },
         {
          label: 'Action',
          field: 'action',
        },
        
      ],
      rows: [],
      searchTerm: '',
      imagepreview:null,
      form : new Form({
        id:0,
        NomSociete:'',
        logo:'',
        phone:'',
        adresse:'',
        Ice:'',
        logo:null,

      })
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.perms=JSON.parse(localStorage.getItem('access'));
      this.getFournis();
      this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    getFournis(){
      this.loading = true
      this.$http.get(`${config.API_BASE_URL}/api/auth/getFourni`)
      .then(res => { this.rows = res.data, this.loading = false })
    },
      AddNewFourni(){
        this.$router.push('/AddNewFournis');
      },
    advanceSearch(val) {
      this.searchTerm = val
    },
        imageSelected(e){
            this.form.logo = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(this.form.logo);
            reader.onload = e => {
            this.imagepreview = e.target.result;
               };
         },
    showUpdate(row){
      this.form.reset();
      this.form.fill(row);
    },
    UpdateFournisseur(){
          let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('Ice', this.form.Ice);
          data.append('NomSociete', this.form.NomSociete);
          data.append('phone', this.form.phone);
          data.append('adresse', this.form.adresse);
          data.append('image', this.form.logo);
          data.append('id',this.form.id);
        this.$http.post(`${config.API_BASE_URL}/api/auth/updateFourni/${this.form.id}`,data) .then(res => {   
            if(res.data=='success'){
              this.getFournis();
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Fournisseur est bien modifié',
                showConfirmButton: false,
                timer: 1000 })
                }else{
                  this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
                }      
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })
    },
    deleteFourni(id){
      let data= new FormData;
      data.append('UserID', this.UserID.id);
      this.$swal.fire({
      text: "Vous voulez supprimer ce Fournisseur ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer!'})
      .then((result) => {
      if (result.value) {
          this.$http.post(`${config.API_BASE_URL}/api/auth/deleteFourni/${id}`,data)
            .then(res=> {
                  if(res.data=='Success'){
                  this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Fournisseur est bien supprimé',
                  showConfirmButton: false,
                  timer: 1500  })   
                  this.getFournis(); 
                }else if(res.data=='error'){
                    this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Vous pouvez pas supprimer ce Fournisseur, Déjà affecter à une facture et/ou Produit. '
                    });
                }    
            }).catch(() => {
                this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong! Pleaz try again'
                    });
            })
        }             
    }) 
    }
/*     onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    }, */
  },
}
</script>
<style scoped>
a.btn.btn-icon.btn-gradient-success.collapsed {

    margin-right: 6px;
    margin-left: 8px;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

